import React, { FC } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import Button from 'gatsby-theme-husky/src/common/Button';
import { multiUrlPickerUtils } from 'gatsby-theme-husky/src/utils';
import { IPropsNavItem } from 'gatsby-theme-husky/src/components/Header/HeaderNavigation/models';

const NavItem: FC<IPropsNavItem> = ({
  navItem: {
    properties: { navigationWithSubMenu, url, label },
  },
  activeMainCategory,
  handleNavigationItemClick,
}) => (
  <li
    className={classNames(`main-navigation-item`, {
      'main-navigation-item-with-sub': navigationWithSubMenu,
      active: activeMainCategory && activeMainCategory === label,
    })}
    onMouseEnter={navigationWithSubMenu ? handleNavigationItemClick(label) : undefined}
    onMouseLeave={handleNavigationItemClick(null)}
  >
    {navigationWithSubMenu ? (
      <Link className="main-nav-link" to={multiUrlPickerUtils.extractUrl(url)}>
        <Button classes="main-nav-link nav-link-with-sub" ariaLabel={label}>
          {label}
        </Button>
      </Link>
    ) : (
      <Link className="main-nav-link" to={multiUrlPickerUtils.extractUrl(url)}>
        {label}
      </Link>
    )}
    {navigationWithSubMenu ? (
      <ul className="secondary-nav">
        {navigationWithSubMenu.map((item) => (
          <li className="secondary-nav__item" key={item.properties.label}>
            <Link
              onClick={handleNavigationItemClick(null)}
              className="main-nav-link secondary-nav__link"
              to={multiUrlPickerUtils.extractUrl(item.properties.url)}
            >
              {item.properties.label}
            </Link>
          </li>
        ))}
      </ul>
    ) : null}
  </li>
);

export default NavItem;
